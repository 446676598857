body {
	background-image: url(./static/images/background_big.jpg);
	background-size: cover;
	display: flex;
  	min-height: 100vh;
  	flex-direction: column;
  	img {
	  	&.logoImage {
		    float: right;
		}
		&.top_left {
		    float: left;
		}
	}
	p {
	    font-size: 1.2em;
	}
  	article {
  		flex: 1;
  		background-image: url(./static/images/shadow.png);
	    background-repeat: no-repeat;
	    background-position: 20% bottom;
        background-color: rgba(210, 183, 115, 0.40);
  		.logo {
  			float: none;
  		}
  		.hideDesktop {
			display: none;
		}
  		audio {
			display: block;
			&::-webkit-media-controls-panel {
		    	background: transparent;
			}
			&::-webkit-media-controls-volume-slider,
			&::-webkit-media-controls-volume-slider-container,
			&::-webkit-media-controls-current-time-display,
			&::-webkit-media-controls-time-remaining-display,
			&::-webkit-media-controls-mute-button {
				display: none;
			}
		}
  		.container-fluid.highlighted {
		    background-color: rgba(255,255,255,0.3);
		    padding: 3em;
		    margin-top: -3em;
		    .container {
			    overflow: hidden;
			    padding: 0px;
			    input[type=radio] {
			    	display: none;
			    }
			    .left_right {
			    	cursor: pointer;
			    }
			    #Slide1:checked ~ .folgen {
				  margin-left: 0%;
				}

				#Slide2:checked ~ .folgen {
				  margin-left: -100%;
				}

				#Slide3:checked ~ .folgen {
				  margin-left: -200%;
				}

				#Slide4:checked ~ .folgen {
				  margin-left: -200%;
				}

				#Slide5:checked ~ .folgen {
				  margin-left: -200%;
				}

				#Slide6:checked ~ .folgen {
				  margin-left: -200%;
				}
			    .folgen {
				    width: 600%;
				    display: flex;
				    -webkit-transition: all 0.3s ease-in-out;
				 	-moz-transition: all 0.3s ease-in-out;
				 	-o-transition: all 0.3s ease-in-out;
				  	transition: all 0.3s ease-in-out;
				    .folge {
			    	    display: inline-block;
				    	flex-grow: 1;
	    				flex-basis: 0;
    				    margin: 0px 30px;
		    		    img.episodeImage {
				  			transform: rotate(-5deg);
						    box-shadow: rgba(0, 0, 0, 0.529412) 3px 2px 39px 0px;
						    margin: 3em 0em;
						    max-width: 80%;
				  		}
				  		.buyButton {
				  			background-color: #b03a1c;
						    border-radius: 10px;
						    display: inline-block;
						    padding: 1em;
						    color: white;
						    font-size: 1.2em;
						    margin: 1em 0em;
				  		}
				    }
				}
				.left_right {
				    text-align: center;
				    img {
				    	margin: 2em;
				    }
				}
			}
		}
		div.penny {
			audio {
				width: 100%;
			}
			div.audiorow {
				background-color: #d3ae67;
				box-shadow: rgba(0, 0, 0, 0.74902) 0px 0px 15px 0px;
			    margin: 2rem 1rem 3rem 1rem;
			}
		}
		.center-block {
  			margin-top: 2em;
		    margin-bottom: 2em;
		}
		.facebookIcon {
			position: absolute;
    		width: 100%;
    		a {
    			position: relative;
    			top: 18em;
    		}
		}
  	}
  	.bottom_left, .bottom_right {
  		/*background-repeat: no-repeat;
    	height: 379px;
    	width: 383;
    	position: absolute;
    	bottom: 0em;
    	*/
  	}
  	.bottom_left {
		background-image: url(./static/images/down_left.png);
    	float: left;
    	left: 0px;
	}
	.bottom_right {
		background-image: url(./static/images/down_right.png);
 		float: right;
 		right: 0px;
	}
	footer.container-fluid {
    	background-color: black;
    	color: #666666;
    	width: 100%;
    	.right {
    		a {
    			text-align: right;
    		}
    	}
    	a {
    		color: #666666;
		    text-decoration: none;
		    margin: 1em 0px;
		    display: block;
    		&:hover {
    			color: #cccccc;
    		}
    	}
	}

}

@media (max-width: $screen-xs-max) {
	body {
		background-image: url(./static/images/background.jpg);
		article {
			background-image: none;
			.hideMobile {
				display: none;
			}
			.hideDesktop {
				display: block;
			}
			.container-fluid.highlighted {
		        margin-top: -1.5em;
		        padding: 0em;
		        .container {
		        	width: 100%;
			        .folgen .folge {
			        	margin: 0px;
		        	    padding: 0.5em 0em;
			        }
		        }
		    }
		    .facebookIcon {
				position: relative;
	    		width: 100%;
	    		margin: 3em 0em -1.5em 0em;
	    		a {
	    			position: relative;
	    			top: 0px;
	    		}
			}
		}
		.bottom_left, .bottom_right, .top_left {
			display: none;
		}
	}	
}
